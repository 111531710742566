import {
  ImageIcon,
  AlertCircle,
  ChevronDown,
  Loader2,
  Sparkles,
  Download,
  Heart,
  ExternalLink,
  HelpCircle,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import confetti from "canvas-confetti";

const RandomImageGenerator = () => {
  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [category, setCategory] = useState("nature");
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [relatedImages, setRelatedImages] = useState([]);
  const [pixabayLink, setPixabayLink] = useState("");
  const API_KEY = "45773955-12834cec6d4ad9467872bbdab";
  const categories = {
    nature: [
      "forest",
      "mountain",
      "ocean",
      "sunset",
      "waterfall",
      "flowers",
      "trees",
      "beach",
      "river",
      "landscape",
    ],
    animals: [
      "dog",
      "cat",
      "lion",
      "elephant",
      "tiger",
      "bear",
      "wolf",
      "eagle",
      "dolphin",
      "penguin",
    ],
    food: [
      "pizza",
      "sushi",
      "burger",
      "salad",
      "pasta",
      "steak",
      "dessert",
      "fruit",
      "vegetables",
      "chocolate",
    ],
    architecture: [
      "skyscraper",
      "bridge",
      "castle",
      "house",
      "temple",
      "cathedral",
      "monument",
      "stadium",
      "museum",
      "tower",
    ],
    technology: [
      "computer",
      "smartphone",
      "robot",
      "gadget",
      "drone",
      "virtual reality",
      "artificial intelligence",
      "blockchain",
      "cybersecurity",
      "internet of things",
    ],
    sports: [
      "football",
      "basketball",
      "tennis",
      "swimming",
      "cycling",
      "running",
      "golf",
      "skiing",
      "surfing",
      "yoga",
    ],
    art: [
      "painting",
      "sculpture",
      "photography",
      "graffiti",
      "illustration",
      "digital art",
      "pottery",
      "mosaic",
      "street art",
      "installation",
    ],
    travel: [
      "city",
      "beach resort",
      "mountain retreat",
      "historical site",
      "national park",
      "island",
      "desert",
      "cruise ship",
      "camping",
      "road trip",
    ],
    fashion: [
      "haute couture",
      "streetwear",
      "vintage",
      "accessories",
      "runway",
      "makeup",
      "hairstyle",
      "shoes",
      "jewelry",
      "designer brands",
    ],
    space: [
      "galaxy",
      "planet",
      "nebula",
      "astronaut",
      "spacecraft",
      "meteor",
      "black hole",
      "star cluster",
      "aurora",
      "space station",
    ],
  };
  const fetchRandomImage = async () => {
    setLoading(true);
    setImageLoaded(false);
    try {
      const randomTerm =
        categories[category][
          Math.floor(Math.random() * categories[category].length)
        ];
      const response = await fetch(
        `https://pixabay.com/api/?key=${API_KEY}&q=${randomTerm}&image_type=photo&per_page=200&orientation=horizontal&safesearch=true`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }
      const data = await response.json();
      if (data.hits && data.hits.length > 0) {
        const randomIndex = Math.floor(Math.random() * data.hits.length);
        setImage(data.hits[randomIndex].webformatURL);
        setPixabayLink(data.hits[randomIndex].pageURL);
        setRelatedImages(
          data.hits.slice(0, 5).map((hit) => ({
            url: hit.webformatURL,
            pageURL: hit.pageURL,
          })),
        );
        setError("");
      } else {
        setError("No images found");
      }
    } catch (err) {
      setError(
        "Error fetching image. Please check your API key and try again.",
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRandomImage();
  }, [category]);
  const handleImageLoad = () => {
    setImageLoaded(true);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: 0.6,
      },
    });
  };
  const handleDownload = async () => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "random_image.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  const handleRelatedImageClick = (relatedImage) => {
    setImage(relatedImage.url);
    setPixabayLink(relatedImage.pageURL);
    setImageLoaded(false);
  };
  const [openFaq, setOpenFaq] = useState(null);

  const faqData = [
    {
      question: "What is a random image generator?",
      answer: "Our random image generator is a tool that provides users with diverse, high-quality images at the click of a button. It's perfect for designers, content creators, or anyone looking for visual inspiration."
    },
    {
      question: "How does the random image generator work?",
      answer: "Our random image generator uses advanced algorithms to select images from a vast database. Each click generates a truly random selection, ensuring a unique experience every time."
    },
    {
      question: "Why use a random image generator?",
      answer: "A random image generator can spark creativity, provide unexpected inspiration, and save time in searching for diverse visual content. It's an excellent tool for brainstorming or finding unique visuals for your projects."
    },
    {
      question: "Is this random image generator free to use?",
      answer: "Yes, our random image generator is completely free to use. We believe in providing accessible tools for creativity and inspiration to everyone."
    }
  ];

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <main className="flex flex-col items-center justify-center min-h-screen p-4 bg-gradient-to-r from-purple-500 to-pink-500 text-white">
      <h1 className="text-4xl font-bold mb-8 animate-pulse">
        Random Image Generator
      </h1>
      <div className="flex items-center mb-8">
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="p-3 border-2 border-yellow-300 rounded-md mr-4 focus:outline-none bg-transparent text-white"
        >
          {Object.keys(categories).map((cat) => (
            <option key={cat} value={cat} className="bg-purple-500">
              {cat.charAt(0).toUpperCase() + cat.slice(1)}
            </option>
          ))}
        </select>
        <button
          onClick={fetchRandomImage}
          className="flex items-center p-3 bg-yellow-300 text-purple-700 rounded-md focus:outline-none transform hover:scale-105 transition-transform duration-200 shadow-lg"
        >
          <ImageIcon className="mr-2" />
          Generate Random Image
        </button>
      </div>
      {error && (
        <div className="flex items-center text-yellow-300 mb-4 animate-bounce">
          <AlertCircle className="mr-2" />
          {error}
        </div>
      )}
      <div className="mt-4 mb-4 flex items-center h-6">
        {loading ? (
          <>
            <Loader2 className="text-yellow-300 mr-2 animate-spin" />
            <span className="text-yellow-300">Loading...</span>
          </>
        ) : (
          <>
            <Sparkles className="text-yellow-300 mr-2" />
            <span className="text-yellow-300">Image loaded successfully!</span>
          </>
        )}
      </div>
      <div className="w-full max-w-2xl h-80 bg-gray-200 rounded-lg overflow-hidden relative border-4 border-yellow-300 transition-transform duration-300 hover:scale-105">
        {loading ? (
          <div className="absolute inset-0 flex items-center justify-center bg-purple-500">
            <div className="w-16 h-16 border-t-4 border-yellow-300 border-solid rounded-full animate-spin"></div>
          </div>
        ) : (
          <img
            src={image}
            alt="Random from Pixabay"
            className={`w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? "opacity-100" : "opacity-0"}`}
            onLoad={handleImageLoad}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </div>
      <div className="mt-4 flex items-center">
        <a
          href={pixabayLink}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-yellow-300 hover:text-white"
        >
          View on Pixabay <ExternalLink className="ml-1" size={16} />
        </a>
      </div>
      {image && (
        <button
          onClick={handleDownload}
          className="mt-4 flex items-center p-3 bg-yellow-300 text-purple-700 rounded-md focus:outline-none transform hover:scale-105 transition-transform duration-200 shadow-lg"
        >
          <Download className="mr-2" />
          Download Image
        </button>
      )}
      <div className="mt-8 grid grid-cols-5 gap-4">
        {relatedImages.map((img, index) => (
          <img
            key={index}
            src={img.url}
            alt={`Related ${index + 1}`}
            className="w-full h-24 object-cover rounded-md cursor-pointer"
            onClick={() => handleRelatedImageClick(img)}
          />
        ))}
      </div>

      {/* Updated FAQ section */}
      <div className="w-full max-w-2xl mt-12">
        <h2 className="text-3xl font-bold mb-6 text-center">
          <HelpCircle className="inline-block mr-2" />
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div key={index} className="border border-yellow-300 rounded-md overflow-hidden">
              <button
                onClick={() => toggleFaq(index)}
                className="w-full p-4 text-left bg-transparent text-yellow-300 hover:bg-yellow-300 hover:bg-opacity-10 transition-colors duration-300 flex justify-between items-center"
              >
                <span>{faq.question}</span>
                <ChevronDown className={`transform transition-transform duration-300 ${openFaq === index ? 'rotate-180' : ''}`} />
              </button>
              <div 
                className={`overflow-hidden transition-all duration-300 ease-in-out ${openFaq === index ? 'max-h-96' : 'max-h-0'}`}
              >
                <p className="p-4 text-yellow-300">{faq.answer}</p>
              </div>
              {/* Hidden content for SEO */}
              <div className="hidden">{faq.answer}</div>
            </div>
          ))}
        </div>
      </div>

      <footer className="mt-auto py-4 text-center">
        <div className="mt-2">
          <span>Made with <Heart className="mr-2 inline text-yellow-300" />in Denver, CO</span>
        </div>
        <p>
          Images from{" "}
          <a
            href="https://pixabay.com"
            className="text-yellow-300 underline hover:text-white"
          >
            Pixabay
          </a>
        </p>
      </footer>
    </main>
  );
};

export default RandomImageGenerator;
